// TODO: i18n
export async function useAuthMenu() {
  const { currentUser } = storeToRefs(useCurrentUserStore())

  const { isStudent, isSchoolAdmin, isMentorOrSchoolAdmin, isCenterAdmin } = useCurrentOrganizationRoles()
  const isMas = useIsMas()

  const { vacancyTypeLabelPlural } = useVacancyTypeLabel()

  const menuItems = computed(() => {
    if (!currentUser) return []

    if (isStudent.value) {
      return [
        {
          name: 'Dashboard',
          icon: { name: 'ph:gauge-duotone' },
          activePath: '/dashboard',
          to: '/dashboard',
        },
        {
          name: 'Stagevoortgang',
          activePath: '/stagevoortgang',
          to: '/stagevoortgang',
        },
        {
          name: 'Stagebank',
          activePath: '/stagebank',
          to: '/stagebank',
        },
        isMas
          ? {
              name: 'Zelfgevonden stage',
              icon: { name: 'ph:briefcase-duotone' },
              activePath: '/zelfgevonden',
              to: '/zelfgevonden',
            }
          : undefined,
        {
          name: 'Berichten',
          activePath: '/conversations',
          to: '/conversations',
        },
      ].filter(i => i !== undefined) as MenuItem[]
    }

    return [
      {
        name: 'Dashboard',
        icon: { name: 'ph:gauge-duotone' },
        activePath: '/dashboard',
        to: '/dashboard',
      },
      isMentorOrSchoolAdmin.value || isCenterAdmin.value
        ? {
            name: 'Organisaties',
            icon: { name: 'ph:buildings' },
            activePath: '/organizations',
            to: '/organizations',
          }
        : undefined,
      {
        name: useCapitalize(vacancyTypeLabelPlural),
        icon: { name: 'ph:buildings' },
        activePath: '/vacancies',
        to: '/vacancies',
      },
      !isCenterAdmin.value
        ? {
            name: 'Stages',
            icon: { name: 'ph:briefcase-duotone' },
            activePath: '/internships',
            to: '/internships',
          }
        : undefined,
      isMentorOrSchoolAdmin.value
        ? {
            name: 'Klassen',
            icon: { name: 'ph:users' },
            activePath: '/groups',
            to: '/groups',
          }
        : undefined,
      {
        name: 'Gebruikers',
        icon: { name: 'ph:users' },
        activePath: '/users',
        to: '/users',
      },
      // isSchoolAdmin.value
      //   ? {
      //       name: 'Templates',
      //       icon: { name: 'ph:files'},
      //       activePath: '/contract_templates',
      //       to: '/contract_templates',
      //     }
      //   : undefined,
      isSchoolAdmin.value
        ? {
            name: 'Instellingen',
            icon: { name: 'ph:files' },
            activePath: '/instellingen',
            to: '/instellingen',
          }
        : undefined,
      {
        name: 'Berichten',
        activePath: '/conversations',
        to: '/conversations',
      },
    ].filter(i => i !== undefined) as MenuItem[]
  })

  const { activeMenuItem, selectedMenuItem } = useActiveMenuItem(
    'auth',
    menuItems,
  )

  return {
    menuItems,
    activeMenuItem,
    selectedMenuItem,
  }
}
